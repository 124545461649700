.Ceramicas_container{
    width: 100%;
}

.Ceramicas_nabbar{
    display: flex;
    background-color: rgb(60, 59, 137);
    min-width: 100%;
    color: white;
    align-items: center;
    text-align: center;
}

.Ceramicas_nabbar_buttons{
    padding: 10px;
    background-color: rgb(26, 26, 59);
    margin-left: 50px;
    margin-top: 15px;
    margin-bottom: 15px;
    border: solid 1px black;
    border-radius: 10px;
}

.Ceramicas_margin{
    margin-left: 50px;
    margin-top: 10px;
}

.ceramicas_filtro{
    margin-left: 50px;
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.ceramicas_filtro_div{
    margin: 0 auto;
}

.ceramicas_filtro_div2{
    margin: 0 auto;
    display: flex;
}

.ceramicas_filtro_button{
    margin-left: 5px;
    margin-top: 30px;
}

.ceramicas_button{
    margin-top: 30px;
}

.ceramicas_check{
    height: 50px;
}
