.Gal_container{
    display: flex;
    width: 100%;
    height: calc(50vw - 100px);
    background-color: white;
}

.Gal_lista{
    width: 100px;
}

.Gal_list_img >img{
    max-width: 100%;
}

.Gal_list_img{
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    border: rgb(227, 228, 233) solid 1px;
    border-radius: 5px;
    padding: 3px;
    margin: 10px auto;
    background-color: white;
}

.Gal_img_img > img{
    max-width: 100%;
    max-height: 100%;
}

.Gal_img{
    height: 100%;
    width: 100%;
    display: flex;
    background-color: white;
    padding: 5%;
}

@media screen and (min-width: 1500px) {
    .Gal_container{
        height: calc(750px - 100px);
    }
}