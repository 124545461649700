/* MOBILE */

.products_mob_container{
    max-width: 90%;
    margin: 20px auto;
    font-family: "Verdana", "Lucida Grande", "Helvetica", Arial, sans-serif;
}

.products_mob_galery{
    height: 200px;
}

.products_mob_sidebar{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.products_mob_title{
    font-size: 1.5em;
}

.products_mob_price_cont{
    margin-top: 15px;
    display: flex;
}

.products_mob_price{
    color: #264796 !important;
    font-weight: bold !important;
}

.products_mob_price_unit{
    margin-top: 11%;
    margin-left: 3px;
    color: gray;
    font-size: 0.5em;
}

.products_mob_pay{
    margin-top: 15px;
}

.products_mob_center{
    margin-top: 15px;
    margin-left: 25%;
}

.products_mob_center_botton{
    padding: 10px;
    font-size: 80%;
    margin-left: 10px;
}

.products_mob_title_rel{
    margin-top: 50px;
    font-family: "Verdana", "Lucida Grande", "Helvetica", Arial, sans-serif;
    font-weight: bold;
    font-size: 1.3em;
    margin-bottom: 15px;
    color: rgb(36, 36, 87);;
}
   

/* DESKTOP*/

.title h1 {
    padding: 20px 0;
    text-align: center;
    font-size: 2.9em;
    text-transform: uppercase;
}

.flex{
    display: flex;
}

.pub_back{
    font-family: "Verdana", "Lucida Grande", "Helvetica", Arial, sans-serif;
    background: #ffffff;
    max-width: 1500px;
    margin: 0 auto;
    margin-top: 30px;
}

.precio_mts{
    margin-top: 40px;
    margin-left: 3px;
    color: gray;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.8em;
}

/* CONTAINER */

.container {
    width: 90%;
    max-width: 1500px;
    margin: 0 auto;
    display: grid;
    gap: 1.0em;
    /* grid-template-columns: repeat(4, 1fr) 3%; */
    grid-template-columns: repeat(6, 1fr);
}

.container>.header, .container>.content, .container>.sidebar, .container>.footer, .container>div {
    margin-top: 20px;
    background: #fff;
    padding: 20px;
}


.container>.public_info{
    grid-column: span 4;
    grid-row: span 6;
    display: grid;
    grid-area: 1.6em;
    grid-template-columns: repeat(3, 1fr);
    border: transparent;
    margin-top: 0;
    max-height: 150px;
}

.products_description{
    grid-column: 1/-1;
}

.products_description_title{
    font-family: Proxima Nova,-apple-system,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
    font-size: 24px;
    font-weight: 400;
    padding-bottom: 24px;
    color: rgba(0,0,0,.8);
}

.products_description_text{
    color: #666;
    font-size: 20px;
    font-family: Proxima Nova,-apple-system,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
}

.products_button_add{
    margin-bottom: 20px;
}

.info_carac{
    display: grid;
    grid-area: 1.6em;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    align-items: center;
    border: transparent !important;
}

.carecteristicas1{
    grid-column: span 1;
    display: grid;
    grid-area: 1.6em;
    grid-template-rows: repeat(3, 1fr);

}

.carecteristicas2{
    grid-column: span 2;
    border-left: transparent;
    display: grid;
    grid-area: 1.6em;
    grid-template-rows: repeat(3, 1fr); 
}

.carac_color1{
    background: #ebebeb;
    border-top-left-radius: 10px;
}

.carac_color4{
    background: #ebebeb;
    border-bottom-left-radius: 10px;
    display: inline-table;

}

.carac_color4>p{
    margin-left: 10px;
    vertical-align: middle;
    position: relative;
    top: 6px;
}


.carac_color1>p{
    margin-left: 10px;
    position: relative;
    top: 6px;
}

.carac_color2{
    background: #f5f5f5;
    border-top-right-radius: 10px;
}

.carac_color2>p{
    margin-left: 10px;
    position: relative;
    top: 6px;
}

.carac_color5{
    background: #f5f5f5;
    border-bottom-right-radius: 10px;
}

.carac_color6>p{
    margin-left: 10px;
    position: relative;
    top: 6px;
}

.carac_color6{
    background: #f5f5f5;
}

.carac_color5>p{
    margin-left: 10px;
    position: relative;
    top: 6px;
}


.carac_color3>p{
    margin-left: 10px;
    position: relative;
    top: 6px;
}


.container>.header, .container>.footer {
    grid-column: 1/-1;
    background-color: #1e272e;
    color: #ffffff;
}

.container>.content {
    grid-column: span 4;
    grid-row: span 3;
    border: transparent;
    background: #ffffff;
    min-height: 50vw;

}

.content_border{
    background: #ffffff;
    padding-bottom: 50px;
    border-bottom: solid 1px rgb(227, 228, 233);
}

.imagen{
    max-width: 100%;
}

.container>.pub_rel{
    grid-column: 1/-1;
    text-align: center;
    border: transparent;
    background: transparent;
    border-top: solid 1px rgb(227, 228, 233);
}

.content>.article {
    background: #808e9b;
    padding: 20px;
    margin-top: 5px;
    color: #dfe6e9
}

.content .article h1,
.content .article p {
    padding: 10px;
}

.container>.sidebar {
    grid-column: span 2;
    grid-row: span 4;
    background: #ffffff;
    color: black;
    display: flex;
    border: transparent;
    flex-direction: column;
    border: solid rgb(227, 228, 233) 1px;
    border-radius: 10px;
}

.container .sidebar .sidebar_title {
    padding: 20px 0;
    font-size: 1.3em;
    text-transform: uppercase;
}

.container .sidebar .sidebar_price {
    padding: 10px 0;
    font-size: 2.0em;
    font-family:Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    color: rgb(18, 18, 238);
}

.container .sidebar .metodos_pago {
    padding: 10px 0;
}

.imagen_tarjeta{
    margin-top: 5px;
    max-height: 30px;
}

.container>.sidebar>.text_row{
    flex-direction: row;
}

.form_cantidad{
    margin-top: 20px;
}

.container>.related-post .text__center {
    visibility: hidden;
    opacity: 0;
    height: 0;
    padding: 0;
}

.container>.related-post:hover .text__center{
    height: auto;
    visibility: visible;
    opacity: 10;
    transition: opacity 0.5s ease;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
}

.container>.related-post img{
    height: 100%;
    width: 100%;
    padding: 0;

}

.container>.related-post:hover img{
    filter: grayscale(100%);
}


.container>.related-post {
    color: #ffbb00;
    height: 180px;
    width: 180px;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
    position: relative;
    display: inline-block;
    text-align: center;
    padding: 0;
    border: transparent;
    background: transparent;
    margin-bottom: 20px;
    margin-top: -20px;
}

.container>.related-post:hover {
    cursor: pointer;
}

/* MEDIA QUERIES */

@media screen and (max-width: 768px) {
    .header {
        text-align: center;
    }
    .container .content {
        grid-column: 1 / -1;
    }
    .container .sidebar {
        grid-column: 1 / 6;
    }

    .container .related-post {
        grid-column: 1/-1;
    }

    .imagen_tarjeta{
        margin-left: 25%;
    }


}

@media screen and (min-width: 1500px) {
    .sidebar{
        height: 650px;
    }
    .container>.public_info{
        margin-top: -9vw;
    }
}