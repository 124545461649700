.cards2_img{
	max-width: 100%;
	max-height: 22vw;
	min-width: 100%;
}

.cards2_img_cont{
	width: 22vw;
	height: 22vw;
	border-bottom: solid 1px rgb(227, 228, 233);
	display: flex;
	align-items: center;
	margin: 0 auto;
	max-width: 100%;
}

.cards2_img_max{
	margin-bottom: 20px;
	border: solid 1px rgb(227, 228, 233);
	height: calc(25vw + 100px);
	margin: 10px;
	background-color: white;
	max-width: 350px;
}

.cards2_img_oferta{
	position: relative;
	background-color: red;
	color: white;
	padding: 5px 10px;
	border-radius: 0 5px 5px 0;
	top: 10px;
	width: 80px;
	font-family: "Montserrat";
    font-size: 20px;
}

.cards2_border{
	border: solid 3px white;
	height: 100%;
}

.cards2_border:hover{
	border: solid 3px #ffbb00;
	height: 100%;
}

.cards2_oferta_off{
	visibility: hidden;
    opacity: 0;
    width: 0;
    padding: 0;
	margin-top: 15px;
}

.cards2_body_cont{
	margin-top: 10px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: "Verdana", "Lucida Grande", "Helvetica", Arial, sans-serif;
    font-size: 100%;
	text-align: center;
	max-width: 100%;
}

.cards2_body_cont_butt{
	margin: 0 auto;
}

.cards2_body_cont2{
	margin-top: 10px;
	width: 100%;
	display: flex;
	align-items: center;
	font-family: "Verdana", "Lucida Grande", "Helvetica", Arial, sans-serif;
    font-size: 100%;
}