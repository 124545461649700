.BannerAdmin{
    margin: 50px;
}

.BannerAdmin_container{
    border: solid 1px black;
    border-radius: 10px;
    margin-top: 20px;
}

.BannerAdmin_img{
    max-width: 100%;
    padding: 10px;
}

.BannerAdmin_cont{
    display: flex;
}

.BannerAdmin_input{
    margin-top: 15px;
    margin-left: auto;
}

.BannerAdmin_save{
    margin-left: auto;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: 10px;
}

.BannerAdmin_margin{
    margin-left: 10px;
}

.BannerAdmin_title{
    text-align: center;
}