.CardAdmin_container{
    border: solid 1px;
    display: flex;
    align-items: center;
    max-width: 90%;
    border-radius: 10px;
}

.CardAdmin_img{
    max-width: 50px;
    min-height: 50px;
    border-radius: 10px;
    margin: 5px;
}

.CardAdmin_img > img{
    max-width: 50px;
    min-height: 50px;
    border-radius: 10px;
}

.CardAdmin_margin{
    margin-left: 10px;
}

.CardAdmin_name{
    width: 23%;
}

.CardAdmin_price{
    width: 10%;
}

.CardAdmin_model{
    width: 23%;
}

.CardAdmin_dimension{
    width: 18%;
}

.CardAdmin_inactive{
    background-color: gray;
}