.header{
    background-color: #1b1464;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
}

.header_fixed{
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 9998;
    background-color: #1b1464;
    box-shadow: #3b3b3b 1px 1px 10px 1px;
}

.fixed_mob{
    top: 0;
    width: 100%;
    z-index: 998;
}

.position_cart_mob_fixed{
    position: relative;
    bottom: 60px;
    left: calc(100% - 100px);
}

.margin_top_fixed_mob{
    margin-bottom: 156px;
}

.header__maximo{
    background-color:  #1b1464 !important;
    border: none !important;
}

.header__maximo_mob{
    background-color:  rgb(60, 59, 137) !important;
    border: none !important;
    position: fixed;
    z-index: 20;
    top: 0;
}

.distancia_mob{
    margin-bottom: 155px;
}

.nav_bar_max{
    z-index: 10;
    position: fixed;
    width: 100%;
    margin: 0;
    top: 0;
}

.header__logo{
    max-height: 120px;
    margin: 0 auto;
    padding: 10px;
    margin-left: 10px;
    margin-top: 5px;
    object-fit: contain;
    align-items: center;
}

.header_search_mob_left{
    position: relative;
    z-index: 1000;
    left: -10px;
}

.search_mobil_off{
    visibility: hidden;
    opacity: 0;
    height: 0;
    padding: 0;
}

.search_mobil{
    margin-left: 40px;
    background-color: #1b1464;
    padding: 3px;
    height: 32px;
    border-radius: 50%;
    z-index: 1000;
}

.search_mobil_add{
    margin-left: 40px;
    background-color: white;
    padding: 3px;
    height: 32px;
    border-radius: 50%;
    z-index: 1000;
}

.text_presupuesto_mob{
    font-size: small;
}



.search_button_mob{
    background-color: transparent !important;
    border: none !important;
    padding: 5px;
    margin-top: -3px;
    z-index: 1000;
}

.search_mobil2{
    background-color: white;
    width: 200px;
    margin-left: -18px;
    height: 32px;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    align-items: center;
    display: flex;
    z-index: 2;
}

.header_icon_search_fixed{
    z-index: 1000;
}

.search_mobil3{
    margin: 0 auto;
    margin-left: 22px;
    max-width: 120px;
    outline: none;
    border: transparent !important;
}

.header__search{
    margin-left: 20px;
    display: flex; 
    border:1px solid #131921;
    border-radius: 5px;
    width: 275px;
    height: 30px;
    float: right;
    background-color: white;
}



.header__searchInput{
    margin-top: 2px !important;
    height: 18px !important;
    padding: 10px !important;
    border: none !important;
    outline: none !important;
    width: 100% !important;
    min-width: 50px !important;
    color: #808080 !important;
}

.header__searchInput:active{
    border: none !important;
    outline: none !important;
}


.header__searchIcon{
    margin-top: 2px;
    margin-right: 2px;
    color: black !important
}

.header__option{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
}

.header__nav{
    display: flex;
    justify-content: space-evenly;
    display: flex;
    margin-left:auto!important;
}

.header__link{
    color: aliceblue !important;
    text-decoration: none !important;
    float: right;
    right: 0px;
    padding-left: 8px;
    padding-right: 8px;
    border: solid 2px #034572;
    border-radius: 5px;
}

.header__link:hover{
    border: solid 2px #ffbb00;
}

.header__optionLineOne{
    font-size: 10px;
}

.header__optionLineTwo{
    font-size: 13px;
    font-weight: 800;
}

.header__basketCount{
    right: 20px;
    bottom: 15px;
    height: 30px;
    width: 30px;
    background-color: #ffbb00;
    border: solid #ffbb00;
    border-radius: 15px;
    text-align: center;
    align-items: center;
    position: relative;
    font-size: 1em;
}

.header_optionBasket{
    display: flex;
    align-items: center;

}

.header_optionBasket_off{
    display: flex;
    align-items: center;
    margin-left: -80px;

}

.header__presupuesto{ 
    top: 15px; 
    position: relative;
    align-items: center;
    text-align: center;

}

.presupuesto__text{
    color: #ffbb00;
}

.presupuesto__price{
    position: relative;
    bottom: 15px;
    
}

.up__line_max{
    border-bottom:solid 1px rgb(26, 26, 59);
    background-color:  #136094;
    padding: 5px;
    padding-top: 7px;
}

.up__line{
    align-items: center;  
    display: flex;
    color: aliceblue;
    max-width: 1500px;
    margin: 0 auto;
    
}

.upp__text{
    margin-left: 1.5%;
    color: white !important;
}

.upp__text2{
    margin-left: 2%;
}

.upp_color_text{
    color: white !important;
    text-decoration: none !important;
}


.upp__text3{
    display: flex;
    margin-right: 10%;
    margin-left:auto!important;
}

.upp__text4{
    display: flex;
    margin-right: 4%;
    align-items: center;
    text-align: center;
    width: 70%;
}

.header_ambiente_mob{
    width: 100%;
    margin-top: 80px;
    display: flex;
    flex-direction: row;
}

.acordion_mob{
    border: solid 1px white;
    z-index: 2000;
    position: absolute;
    bottom: 20px;
    width: 10vw;
    left: 10px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 3px;
}

.header_hika{
    width: 100vw;
    margin-top: -40px;
    box-shadow: #3b3b3b 1px 1px 10px 1px;
}

.header_ambiente_mob_img{
    max-height: 40px;
    position: relative;
    top: -60px;
    left: 100px;
}



@media screen and (max-width: 768px) {
    .up__line{
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    }
    .up__line_max{
        background-color: #136094;
        border-bottom:solid 1px #136094;
    }

    .upp__text{
        margin: 0 auto;
        font-size: 80%;
        border: white 1px solid;
        border-radius: 5px;
        padding: 5px;
        background-color: rgb(26, 26, 59);
    }
    
    .upp__text2{
        margin: 0 auto;
        font-size: 80%;
        border: white 1px solid;
        border-radius: 5px;
        padding: 5px;
        background-color: rgb(26, 26, 59); 
    }
    
    .upp__text3{
        margin: 0 auto;
        font-size: 80%;
        border: white 1px solid;
        border-radius: 5px;
        padding: 5px;
        background-color: rgb(26, 26, 59);
    }

    .header__logo{
        margin-left: 1%;
        max-width: 75%;
        padding-top: 0;
        padding-bottom: 5px;
    }

    .header__searchInput{
        height: 30px;
    }

    .header{
        flex-direction: column;
    }

    .upp__text4{
        display: flex;
        margin: 0 auto;
        margin-block-end: 5px;
        margin-left: 48px;
    }

    .header__presupuesto{
        visibility: hidden;
        opacity: 0;
        height: 0;
        padding: 0;
        width: 0;
        position: absolute;
        left: -20px;
    }

    .header_fixed{
        max-height: 80px;
    }
    
    .header__search{
        height: 40px;
        min-width: 150px !important;
        margin-right: 20px;
        position: relative;
        right: -10px;
    }
    
    .header__searchIcon{
        margin-top: 7px;
    }

    .header__nav{
        margin-right: -45px;
        position: relative;
        flex-direction: column;

    }

    .header__link{
        border: rgb(60, 59, 137);
    }

    .header__link:hover{
        border: rgb(60, 59, 137);
    }

    .header_optionBasket{
        margin-left: -70px;
    }

}

@media screen and (max-width: 640px) {
    .header__search{
        margin-left: 30px;
    }

}