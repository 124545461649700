@import url("https://use.typekit.net/rdn6bow.css");

.checkout{
    max-width: 1500px;
    width: 90%;
    margin: 30px auto;
    display: grid;
    gap: 1.0em;
    /* grid-template-columns: repeat(4, 1fr) 3%; */
    grid-template-columns: repeat(3, 1fr);
    font-family: source-sans-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
    
}

.checkout_title_max{
    grid-column: 1 / -1;
    text-align: center;
    font-size: 300%;
    margin-bottom: 10px;
    background: #7f8b94;
    color: white;
    border-bottom: solid 1px #6b747b;
}

.checkout_cont_vol{
    border: solid 1px rgb(227, 228, 233);
    background-color: rgb(227, 228, 233);
    border-radius: 10px;
}

.checkout_product{
    grid-column:  span 2;
}

.checkout_title{
    margin-right: 10px;
    padding: 10px;
    border-bottom: 1px solid lightgray;
}

.check_ad{
    max-width: 80%;
    margin: 25px auto;
}

.checkout_id_inv{
        visibility: hidden;
        opacity: 0;
        height: 0;
        padding: 0;
}

.checkout_voleta{
    grid-column:  span 1;
    max-height: 100%;
}

.chekout_subtotal{
    max-width: 85%;
    margin: 0 auto;
    grid-template-columns: repeat(3, 1fr);
    display: grid;
}

.checkout_voleta_text{
    margin-bottom: 15px;
    grid-column:  span 2;
}

.checkout_voleta_price{
    margin-bottom: 15px;
    margin-left: auto;
}

.checkout_voleta_price2{
    margin-left: auto;
}

.checkout_voleta_text2{
    grid-column:  span 2;
}



.checkout_border_bot{
    border-bottom: black solid 1px;
    max-width: 80%;
    margin: 25px auto;
}

.checkout_presu_title{
    margin-top: 30px;
    text-align: center;
    font-size: x-large;
}

.checkout_buttons{
    margin: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    align-content: center;
}

.checkout_buttons2{
    display: flex;
    margin: 0 auto;
    text-decoration: none !important;
}

.checkout_button_separated1{
    margin-left: 7px;
}

.checkout_button_separated2{
    margin-right: 7px;
}

@media screen and (max-width: 768px) {
    .checkout_product{
        grid-column: 1 / -1;
    }

    .checkout_voleta{
        grid-column: 1 / -1;
    }
}