.AdminInicio{
    max-width: 1000px;
    margin: 0 auto;
}

.AdminInicio_cards_contador{
    width: 30%;
    height: 100px;
    display: flex; 
    color: white;
    margin: 0 auto;
}

.AdminInicio_cards_text{
    margin-top: 10px;
    margin-left: 20px;
    margin-right: -20px;
    width: 70%;
}

.AdminInicio_cards_icon{
    background-color: white;
    width: 42px;
    height: 42px;
    align-items: center;
    padding: 6px;
    border-radius: 50%;
    margin-top: 29px;
}

.AdminInicio_cards_text_number{
    font-size: 1.7em;
    text-align: center;
}

.AdminInicio_cards_text_desc{
    text-align: center;
    font-size: 1.1em;
}

.AdminInicio_cards_contador_container{
    display: flex;
    margin: 45px auto;
}

.AdminInicio_cards_color1{
    border: solid 1px #375804;
    background-color: #68ae00;
}

.AdminInicio_cards_color2{
    border: solid 1px #a15107;
    background-color: #fc8213;
}

.AdminInicio_cards_color3{
    border: solid 1px #09355c;
    background-color: #347ab8;
}

.AdminInicio_grafic{
    display: flex;
}

.AdminInicio_grafic_cards{
    width: 45%;
    margin: 45px auto;
    background-color: white;
}