.category_container{
    max-width: 1500px;
    z-index: 1;
    max-width: 1500px;
    background-color: white;
    display: grid;
    /* grid-template-columns: repeat(4, 1fr) 3%; */
    grid-template-columns: repeat(4, 1fr);
}

.category_title{
    margin: 20px auto;
}

.category_max{
    max-width: 1500px;
    margin: 0 auto;
}

.category_bottom{
    margin-top: 15px;
    border-bottom: solid 1px rgb(227, 228, 233);
    border-top: solid 1px rgb(227, 228, 233);
}


@media screen and (max-width: 768px) {
    .category_container{
        grid-template-columns: repeat(2, 1fr);
    }
}