.container1{
    z-index: 1;
    min-width: 100%;
    display: grid;
    /* grid-template-columns: repeat(4, 1fr) 3%; */
    grid-template-columns: repeat(4, 1fr);
    border: solid 1px #d9d9d9;
    margin-block-end: 50px;
    border-top: transparent;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: #ffffff;
}

.modalpading{
    padding: 0 !important;
    margin: 0 !important;
}

.pub__card{
    grid-column: span 1;
    margin-left: 0px;
    margin-right: 0px;
    grid-column: span 1;
    height: 75px;
    align-items: center;
    text-decoration: none !important;
}

.flex{
    display: flex;
    text-decoration: none !important;
}

.pub__cent{
    margin-left: 15px;
    margin-top: 16px;
    display: flex;
}

.text{
    margin-left: 10px;
    margin-top: 7px;
    color: #808080 !important;
    text-decoration: none !important;
}


.p1{
    border-top: solid #00a1f1 4px;
    border-right: solid 1px #d9d9d9;
}

.p2{
    border-top: solid #ea4335 4px;
    border-right: solid 1px #d9d9d9;
}

.p3{
    border-top: solid #ffbb00 4px;
    border-right: solid 1px #d9d9d9;
}

.p4{
    border-top: solid #7bbb00 4px;
}

.publicidad_cursor{
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .pub__card{
        grid-column: span 4;
    }

    .container1{
        margin-top: 50px;
    }
}