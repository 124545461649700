.label{
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 100%;
    color: white;
}

.back__color{
    background-color : aliceblue;

}

.ambiente_desplegable{
    z-index: 2000;
    position: relative;
}

.prueba_ambiente{
    display: grid;
    border: solid #1b1464 1.5px;
    max-width: 1500px;
    grid-template-columns: repeat(5, 1fr);
    border-radius: 4px;
    margin: 0 auto;
}

.prueba_ambiente2{
    border-right: solid 1px #1b1464;
    align-items: center;
    text-align: center;
    padding-top: 10px;
    height: 40px; 
    background-color: aliceblue;
    text-decoration: none !important;
    color: #1b1464 !important;
}

.ambiente_desplegable_max{
    position: relative;
    display: flex;
    flex-direction: column;
}

.ambiente_text{
    text-decoration: none !important;
    color: #1b1464 !important;
}

.ambiente_text_hover{
    color:white !important;
    text-decoration: none !important;
}

.distancia{
    min-width: 20%;
    text-align: center;
    font-size: 1.2em;
    transition: all 0.3s ease;
    color: black !important;
    border: solid 1px aliceblue;
    text-decoration: none !important;
}

.d1, .d2, .d3, .d4{
    border-right: solid 1px #1b1464;
}

.d1{
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.d5{
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    width: calc(100% + 1px);
}

.d1:hover{
    background: #00a0e3;
    color: white !important;
}

.dal1{
    background: #00a0e3;
    color: white !important  
}

.dal2{
    background: #f36d00;
    color: white !important;
    border-left: solid 1px #f36d00;
}

.dal3{
    background: #6c2282;
    color: white !important;
    border-left: solid 1px #6c2282;
}

.dal4{
    background: #198a19;
    color: white !important;
    border-left: solid 1px #198a19;  
}

.d2:hover{
    background: #f36d00;
    color: white !important;
    border-left: solid 1px #f36d00;
}

.d3:hover{
    background: #6c2282;
    color: white !important;
    border-left: solid 1px #6c2282;
}

.d4:hover{
    background: #198a19;
    color: white !important;
    border-left: solid 1px #198a19;
}

.d5:hover{
    background: #e31e24;
    color: white !important;
    border-left: solid 1px #e31e24;
    
}

.margen{
margin-left: 20%;
}

.ambiente__max{
    background-color: #1b1464;
    width: 100%;
    position: relative;
    margin-top: 160px;
}

.ambiente__container{
    margin: 0 auto;
    border-radius: 4px;
    border: aliceblue solid 2px;
    max-width: 1500px;
}

@media screen and (max-width: 768px) {
    .back__color{
        z-index: 2000;
        margin-top: -50px;
        position: absolute;
        background-color : #1b1464;
        margin-block-end: 20px;
        color: white !important;
    }

    .ambiente_icon{
        border: solid 1px white;
        z-index: 2000;
        position: absolute;
        bottom: 0px;
        width: 10vw;
        left: 10px;
        display: flex;
        align-items: center;
        border-radius: 5px;
        padding: 3px;
    }

    .ambiente_deploy{
        background-color: white;
        z-index: 5000;
        margin-bottom: -8px;
        min-height: 100%;
    }

    .ambiente_deploy_link{
        margin: 0 auto;
        color: black !important
    }

    .ambiente_deploy1{
        border-top: solid 5px white;
    }

    .ambiente_deploy_cont{
        min-width: 100vw;
        display: flex;
        align-items: center;
    }

    .ambiente_deploy_arrow{
        margin-left: auto;
    }

    .distancia{
        color: white !important;
        border: transparent;
    }

    .ambiente__container{
        border: transparent;
    }

    .ambiente__max{
        margin-top: -2%;
    }

    .d1:hover ,.d2:hover,.d3:hover,.d4:hover, .d5:hover{
        background:transparent;
        border: transparent;
    }

}

