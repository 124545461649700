.AdminPresupuesto{
    align-items: center;
    display: flex;
    flex-direction: column;
}

.AdminPresupuesto_busqueda{
    display: flex;
}

.AdminPresupuesto_busqueda_title{
    margin: 20px;
}

.AdminPresupuesto_busqueda_button{
    margin-top: 52px;
}

.AdminPresupuesto_card{
    border: solid black 1px;
    margin: 10px;
    width: 50vw;
    background-color: white;
    border-radius: 10px;
}

.AdminPresupuesto_cont{
    border: solid black 1px;
    border-radius: 10px;
    margin: 10px auto;
    width: 90%;
}

.AdminPresupuesto_card_title{
    margin: 5px;
    text-align: center;
}

.AdminPresupuesto_prod{
    margin: 10px auto;
    width: 90%;
    text-align: center;
}

.AdminPresupuesto_card_buttons{
    align-items: center;
    display: flex;
}

.AdminPresupuesto_card_button{
    margin: 5px auto;
}

.AdminPresupuesto_buttonL{
    margin-left: 8px;
}

.AdminPresupuesto_buttonR{
    margin-right: 8px;
}