@import url("https://use.typekit.net/rdn6bow.css");

.cardCheck_container{
    max-height: 200px;
    width: 90%;
    margin: 10px auto;
    display: grid;
    gap: 1.0em;
    /* grid-template-columns: repeat(4, 1fr) 3%; */
    grid-template-columns: repeat(3, 1fr);
    font-family: source-sans-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
    
}

.cardCheck_container_abs{
    border-bottom: solid 1px rgb(227, 228, 233);
}

.cardCheck_imgcont{
    grid-column:  span 1;
    display: grid;
}

.cardCheck_img{
    max-height: 200px;
    max-width: 100%;
    border-radius: 10px;
}

.cardCheck_info{
    margin-top: 5px;
    grid-column:  span 2;
}


.cardCheck_button{
    display: flex;
    margin-top: 3%;
}

.cardCheck_cantidad{
    display: flex;
    font-weight: 400 !important;
    color: #494d59;
}

.cardCheck_espaciado{
    margin-top: 2%;
}

.cardCheck_cantidad2{
    margin-left: 20px;
}

.cardCheck_button_espaciado{
    margin-right: 5px;
}

@media screen and (max-width: 768px) {
    .cardCheck_imgcont{
        grid-column: 1 / -1;
        align-items: center;
        text-align: center;
        margin: 0 auto;
    }



    .cardCheck_info{
        grid-column: 1 / -1;
    }

    .cardCheck_container{
        max-height: none;
        align-items: center;
        text-align: center;
        border-bottom: none;
    }

    .cardCheck_cantidad{
        display: flex;
        text-align: center;
        align-items: center;
        margin-left: 25%;
    }

    .cardCheck_button{
        display: flex;
        align-items: center;
        text-align: center;
        margin-left: 25%;
    }

    .cardCheck_cel{
        margin-left: 37% !important;
    }

    .cardCheck_font_weight{
        font-weight: 400 !important;
    }

}