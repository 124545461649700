.adminHome_container{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    border-radius: 4px;
    margin: 0 auto;
}

.adminHome_header{
    background-color: rgb(60, 59, 137);
    min-width: 100%;
    padding: 5px;
}

.adminHome_header_button{
    margin-left: 90vw;
}

.adminHome_bar{
    background-color: rgb(60, 59, 137);
    display: flex;
    flex-direction: column;
}

.adminHome_img{
    margin: 25px auto;
    max-width: 90%;
    margin-left: 5px;
}

.adminHome_bar_container{
    display: flex;
    flex-direction: column;
}

.clas_border{
    border-top: solid 2px white;
    padding: 10px;
    color: white;
    text-align: center;
}

.clas_border:hover{
    color: white !important;
    text-decoration: none !important;
}

.clas_border_botton{
    border-top: solid 2px white;
    min-height: 650px;
    color: rgb(60, 59, 137);
}

.adminHome_component{
    grid-column: span 6;
}

.adminHome_route_in{
    background-color: rgb(26, 26, 59);
}