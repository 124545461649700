.CarouselAdmin_img{
    max-height: 100px;
    border-radius: 10px;
}

.CarouselAdmin_container{
    border: solid 1px black;
    border-radius: 10px;
    display: flex;
    max-height: 100px;
    margin-top: 20px;
}

.CarouselAdmin{
    margin: 50px;
}

.CarouselAdmin_timer{
    margin-top: 35px;
    margin-left: 25px;
}

.CarouselAdmin_timer_width{
    min-width: 20vw;
}

.CarouselAdmin_timer>input{
    max-width: 100px;
}