.pagos1, .pagos2{
    margin-top: 50px;
    border-block-end: solid 1px rgb(227, 228, 233);
    display: solid;
    margin:auto;
}

.pagos_grid{
    grid-column: span 1;
    grid-row: span 2;
    margin: auto;
}

.pagos_header{
    z-index: 1;
    display: grid;
    margin: 0;
}

@media screen and (max-width: 768px) {


    .pagos1{
        border-block-end: none;
        max-width: 768px;
        display: none;
    }

    .pagos2{
        position: relative;
        bottom: 53px;
        display: none;
    }



}