.NewProduct_container{
    margin: 0 auto;
    max-width: 40%;
    border: solid 1px black;
    border-radius: 10px;
    background-color: #f5f5f5;
    padding: 50px;
    text-align: center;
}

.NewProduct{
    background-color: white;
}

.NewProduct_modal_marg{
    margin-top: 30px;
}

.NewProduct_button{
    margin-left: 10px;
}

.NewProduct_modal{
    background-color: rgb(26, 26, 59);
    border: none;
    color: white;
}

.NewProduct_modal:hover{
    background-color: rgb(26, 26, 59);
}

.NewProduct_alert{
    position: fixed;
    top: 0;
    left: 5vw;
    width: 90vw;
    z-index: 2001;
    text-align: center;
}

.NewProduct_prev_img{
    max-width: 75px;
    margin-right: 5px;
    border-radius: 5px;
    
}

.NewProduct_prev_img_cont{
    display: flex;
    
}

.NewProduct_img_number{
    position: relative;
    bottom: 53%;
    left: 33%;
    color: white;
    font-size: 2.5em;
}

.NewProduct_description{
    display: flex;
    flex-direction: column;
}