

.modal_header{
    background: rgb(60, 59, 137) !important;
    border-radius: 0 !important;
    outline: none;
}

.modal_title{
    color: white !important;
}

.modal_footer{
    border-radius: 0 !important;
    background: white;
}

.modal_max{
    margin-top: 110px;
}

.modal_envios{
    z-index: 1000000000;
}