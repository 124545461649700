.footer{
    margin-top: 0 !important;

}

.footer_cont{
    max-width: 1500px;
    margin: 0 auto;
}

.footer__back{
    margin-top: 0;
    border-top: solid 3px rgb(33, 33, 61);
    background-color: #1b1464;
}

.footer__inf{
    background-color:  rgb(33, 33, 61);
}

.footer__letra{
    color: snow;
}

.footer__margin{
    margin-top: 5px;
    margin-left: 10px;
}

.footer__orden{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.footer_lineado{
    border-right:dashed 1px white;
}


.footer__icon{
    size: 1.5em;
    gap: 1.5em;
    margin-left: -10%;
}

.footer__icon2{
    size: 1.5em;
    gap: 1.5em;
    margin-top: 2px;
    margin-left: 10%;
}

.imagenfb{
    max-width: 37px;
}

.imagenig{
    max-width: 30px;
    
}

.footer_map{
    position: absolute;
    max-width: 100px;
    max-height: 100px;
    z-index: 10;
}

.footer_envios{
    margin-left: 5px;
}

.footer_title{
    text-align: center;
    align-items: center;
    margin-top: -20px;
    margin-bottom: 10px;
}

.footer_title2{
    text-align: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.footer_dirname{
    text-align: center;
}


.footer_direccion{
    max-width: 300px;
    margin-bottom: 10px;

}

.footer_truck{
    display: flex;
    align-items: center;
    margin-left: -15%;
}

.footer_logo_BPA{
    max-height: 50px;
    opacity: 0.7;
}



@media screen and (max-width: 768px) {

.footer__icon{
    size: 1.5em;
    gap: 1.5em;
    margin-left: 25%;
    margin-right: -25%;
    font-size: 1.5em;
}

.footer__icon2{
    size: 1.5em;
    gap: 1.5em;
    margin: 0 auto;
    font-size: 1.5em;
}

.footer_title{
    margin-top: 10px;
}

.footer_lineado{
    border-right: none;
}

.footer__margin{
    margin-left: -3%;
}

.footer_dirname{
    margin-left: -2%;
}
.footer_direccion{
    margin-left: -15%;
}

}