.Edit_images_img{
    max-width: 100%;
}

.Edit_images_img_cont{
    width: 80px;
    height: 80px;
    border: solid 1px gray;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin: 5px;
}

.Edit_images_cont{
    display: flex;
}

.Edit_images_img_shadow{
    -webkit-box-shadow: 0px 0px 19px 0px blue; 
    box-shadow: 0px 0px 19px 0px blue;
    border: solid 1px blue !important;
}

.Edit_images_delete{
    background-color: red;
    color: white;
    width: 22px;
    text-align: center !important;
    align-items: center !important;
    margin: 0 auto;
    border-radius: 5px;
    cursor: pointer;
}