.carousel_cards{
    width: 100%;
    margin: 0 auto;
}

.carouselc_container{
    margin: 0 auto;
    border-top: solid 1px rgb(227, 228, 233);
    border-bottom: solid 1px rgb(227, 228, 233);
    border-right: solid 1px rgb(227, 228, 233);
}

.carousel-button-group{
    position: absolute;
}

.cards_caro{
    border-left: solid 1px rgb(227, 228, 233);
}

.cards_caro_ultimo{
    border-left: solid 1px rgb(227, 228, 233);
    border-right: solid 1px rgb(227, 228, 233);
}
