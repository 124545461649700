@import url(https://use.typekit.net/rdn6bow.css);
@import url(https://use.typekit.net/rdn6bow.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  margin: 0;
  font-family: Arial;
  background-color: #f5f5f5;
  }


.header{
    background-color: #1b1464;
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
}

.header_fixed{
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 9998;
    background-color: #1b1464;
    box-shadow: #3b3b3b 1px 1px 10px 1px;
}

.fixed_mob{
    top: 0;
    width: 100%;
    z-index: 998;
}

.position_cart_mob_fixed{
    position: relative;
    bottom: 60px;
    left: calc(100% - 100px);
}

.margin_top_fixed_mob{
    margin-bottom: 156px;
}

.header__maximo{
    background-color:  #1b1464 !important;
    border: none !important;
}

.header__maximo_mob{
    background-color:  rgb(60, 59, 137) !important;
    border: none !important;
    position: fixed;
    z-index: 20;
    top: 0;
}

.distancia_mob{
    margin-bottom: 155px;
}

.nav_bar_max{
    z-index: 10;
    position: fixed;
    width: 100%;
    margin: 0;
    top: 0;
}

.header__logo{
    max-height: 120px;
    margin: 0 auto;
    padding: 10px;
    margin-left: 10px;
    margin-top: 5px;
    object-fit: contain;
    align-items: center;
}

.header_search_mob_left{
    position: relative;
    z-index: 1000;
    left: -10px;
}

.search_mobil_off{
    visibility: hidden;
    opacity: 0;
    height: 0;
    padding: 0;
}

.search_mobil{
    margin-left: 40px;
    background-color: #1b1464;
    padding: 3px;
    height: 32px;
    border-radius: 50%;
    z-index: 1000;
}

.search_mobil_add{
    margin-left: 40px;
    background-color: white;
    padding: 3px;
    height: 32px;
    border-radius: 50%;
    z-index: 1000;
}

.text_presupuesto_mob{
    font-size: small;
}



.search_button_mob{
    background-color: transparent !important;
    border: none !important;
    padding: 5px;
    margin-top: -3px;
    z-index: 1000;
}

.search_mobil2{
    background-color: white;
    width: 200px;
    margin-left: -18px;
    height: 32px;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    align-items: center;
    display: flex;
    z-index: 2;
}

.header_icon_search_fixed{
    z-index: 1000;
}

.search_mobil3{
    margin: 0 auto;
    margin-left: 22px;
    max-width: 120px;
    outline: none;
    border: transparent !important;
}

.header__search{
    margin-left: 20px;
    display: flex; 
    border:1px solid #131921;
    border-radius: 5px;
    width: 275px;
    height: 30px;
    float: right;
    background-color: white;
}



.header__searchInput{
    margin-top: 2px !important;
    height: 18px !important;
    padding: 10px !important;
    border: none !important;
    outline: none !important;
    width: 100% !important;
    min-width: 50px !important;
    color: #808080 !important;
}

.header__searchInput:active{
    border: none !important;
    outline: none !important;
}


.header__searchIcon{
    margin-top: 2px;
    margin-right: 2px;
    color: black !important
}

.header__option{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
}

.header__nav{
    display: flex;
    justify-content: space-evenly;
    display: flex;
    margin-left:auto!important;
}

.header__link{
    color: aliceblue !important;
    text-decoration: none !important;
    float: right;
    right: 0px;
    padding-left: 8px;
    padding-right: 8px;
    border: solid 2px #034572;
    border-radius: 5px;
}

.header__link:hover{
    border: solid 2px #ffbb00;
}

.header__optionLineOne{
    font-size: 10px;
}

.header__optionLineTwo{
    font-size: 13px;
    font-weight: 800;
}

.header__basketCount{
    right: 20px;
    bottom: 15px;
    height: 30px;
    width: 30px;
    background-color: #ffbb00;
    border: solid #ffbb00;
    border-radius: 15px;
    text-align: center;
    align-items: center;
    position: relative;
    font-size: 1em;
}

.header_optionBasket{
    display: flex;
    align-items: center;

}

.header_optionBasket_off{
    display: flex;
    align-items: center;
    margin-left: -80px;

}

.header__presupuesto{ 
    top: 15px; 
    position: relative;
    align-items: center;
    text-align: center;

}

.presupuesto__text{
    color: #ffbb00;
}

.presupuesto__price{
    position: relative;
    bottom: 15px;
    
}

.up__line_max{
    border-bottom:solid 1px rgb(26, 26, 59);
    background-color:  #136094;
    padding: 5px;
    padding-top: 7px;
}

.up__line{
    align-items: center;  
    display: flex;
    color: aliceblue;
    max-width: 1500px;
    margin: 0 auto;
    
}

.upp__text{
    margin-left: 1.5%;
    color: white !important;
}

.upp__text2{
    margin-left: 2%;
}

.upp_color_text{
    color: white !important;
    text-decoration: none !important;
}


.upp__text3{
    display: flex;
    margin-right: 10%;
    margin-left:auto!important;
}

.upp__text4{
    display: flex;
    margin-right: 4%;
    align-items: center;
    text-align: center;
    width: 70%;
}

.header_ambiente_mob{
    width: 100%;
    margin-top: 80px;
    display: flex;
    flex-direction: row;
}

.acordion_mob{
    border: solid 1px white;
    z-index: 2000;
    position: absolute;
    bottom: 20px;
    width: 10vw;
    left: 10px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 3px;
}

.header_hika{
    width: 100vw;
    margin-top: -40px;
    box-shadow: #3b3b3b 1px 1px 10px 1px;
}

.header_ambiente_mob_img{
    max-height: 40px;
    position: relative;
    top: -60px;
    left: 100px;
}



@media screen and (max-width: 768px) {
    .up__line{
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    }
    .up__line_max{
        background-color: #136094;
        border-bottom:solid 1px #136094;
    }

    .upp__text{
        margin: 0 auto;
        font-size: 80%;
        border: white 1px solid;
        border-radius: 5px;
        padding: 5px;
        background-color: rgb(26, 26, 59);
    }
    
    .upp__text2{
        margin: 0 auto;
        font-size: 80%;
        border: white 1px solid;
        border-radius: 5px;
        padding: 5px;
        background-color: rgb(26, 26, 59); 
    }
    
    .upp__text3{
        margin: 0 auto;
        font-size: 80%;
        border: white 1px solid;
        border-radius: 5px;
        padding: 5px;
        background-color: rgb(26, 26, 59);
    }

    .header__logo{
        margin-left: 1%;
        max-width: 75%;
        padding-top: 0;
        padding-bottom: 5px;
    }

    .header__searchInput{
        height: 30px;
    }

    .header{
        flex-direction: column;
    }

    .upp__text4{
        display: flex;
        margin: 0 auto;
        -webkit-margin-after: 5px;
                margin-block-end: 5px;
        margin-left: 48px;
    }

    .header__presupuesto{
        visibility: hidden;
        opacity: 0;
        height: 0;
        padding: 0;
        width: 0;
        position: absolute;
        left: -20px;
    }

    .header_fixed{
        max-height: 80px;
    }
    
    .header__search{
        height: 40px;
        min-width: 150px !important;
        margin-right: 20px;
        position: relative;
        right: -10px;
    }
    
    .header__searchIcon{
        margin-top: 7px;
    }

    .header__nav{
        margin-right: -45px;
        position: relative;
        flex-direction: column;

    }

    .header__link{
        border: rgb(60, 59, 137);
    }

    .header__link:hover{
        border: rgb(60, 59, 137);
    }

    .header_optionBasket{
        margin-left: -70px;
    }

}

@media screen and (max-width: 640px) {
    .header__search{
        margin-left: 30px;
    }

}
.label{
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 100%;
    color: white;
}

.back__color{
    background-color : aliceblue;

}

.ambiente_desplegable{
    z-index: 2000;
    position: relative;
}

.prueba_ambiente{
    display: grid;
    border: solid #1b1464 1.5px;
    max-width: 1500px;
    grid-template-columns: repeat(5, 1fr);
    border-radius: 4px;
    margin: 0 auto;
}

.prueba_ambiente2{
    border-right: solid 1px #1b1464;
    align-items: center;
    text-align: center;
    padding-top: 10px;
    height: 40px; 
    background-color: aliceblue;
    text-decoration: none !important;
    color: #1b1464 !important;
}

.ambiente_desplegable_max{
    position: relative;
    display: flex;
    flex-direction: column;
}

.ambiente_text{
    text-decoration: none !important;
    color: #1b1464 !important;
}

.ambiente_text_hover{
    color:white !important;
    text-decoration: none !important;
}

.distancia{
    min-width: 20%;
    text-align: center;
    font-size: 1.2em;
    transition: all 0.3s ease;
    color: black !important;
    border: solid 1px aliceblue;
    text-decoration: none !important;
}

.d1, .d2, .d3, .d4{
    border-right: solid 1px #1b1464;
}

.d1{
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.d5{
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    width: calc(100% + 1px);
}

.d1:hover{
    background: #00a0e3;
    color: white !important;
}

.dal1{
    background: #00a0e3;
    color: white !important  
}

.dal2{
    background: #f36d00;
    color: white !important;
    border-left: solid 1px #f36d00;
}

.dal3{
    background: #6c2282;
    color: white !important;
    border-left: solid 1px #6c2282;
}

.dal4{
    background: #198a19;
    color: white !important;
    border-left: solid 1px #198a19;  
}

.d2:hover{
    background: #f36d00;
    color: white !important;
    border-left: solid 1px #f36d00;
}

.d3:hover{
    background: #6c2282;
    color: white !important;
    border-left: solid 1px #6c2282;
}

.d4:hover{
    background: #198a19;
    color: white !important;
    border-left: solid 1px #198a19;
}

.d5:hover{
    background: #e31e24;
    color: white !important;
    border-left: solid 1px #e31e24;
    
}

.margen{
margin-left: 20%;
}

.ambiente__max{
    background-color: #1b1464;
    width: 100%;
    position: relative;
    margin-top: 160px;
}

.ambiente__container{
    margin: 0 auto;
    border-radius: 4px;
    border: aliceblue solid 2px;
    max-width: 1500px;
}

@media screen and (max-width: 768px) {
    .back__color{
        z-index: 2000;
        margin-top: -50px;
        position: absolute;
        background-color : #1b1464;
        -webkit-margin-after: 20px;
                margin-block-end: 20px;
        color: white !important;
    }

    .ambiente_icon{
        border: solid 1px white;
        z-index: 2000;
        position: absolute;
        bottom: 0px;
        width: 10vw;
        left: 10px;
        display: flex;
        align-items: center;
        border-radius: 5px;
        padding: 3px;
    }

    .ambiente_deploy{
        background-color: white;
        z-index: 5000;
        margin-bottom: -8px;
        min-height: 100%;
    }

    .ambiente_deploy_link{
        margin: 0 auto;
        color: black !important
    }

    .ambiente_deploy1{
        border-top: solid 5px white;
    }

    .ambiente_deploy_cont{
        min-width: 100vw;
        display: flex;
        align-items: center;
    }

    .ambiente_deploy_arrow{
        margin-left: auto;
    }

    .distancia{
        color: white !important;
        border: transparent;
    }

    .ambiente__container{
        border: transparent;
    }

    .ambiente__max{
        margin-top: -2%;
    }

    .d1:hover ,.d2:hover,.d3:hover,.d4:hover, .d5:hover{
        background:transparent;
        border: transparent;
    }

}


.banner_width {
    width: 100%;

}

@media screen and (max-width: 768px) {
    
    .banner_width {
        margin-top: 2%;
    }


}
.home{
    margin-top: 5%;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    background-color: #f5f5f5;
}

.prueba_cad2_1{
    display: grid;
    max-width: 365px;
}

.home__row{
    z-index: 1;
    margin-right: 5px;
    margin-left: 5px;
    display: grid;
    /* grid-template-columns: repeat(4, 1fr) 3%; */
    grid-template-columns: repeat(4, 1fr);
}

.home_conteiner_grid{
    max-width: 1500px;
    z-index: 1;
    background-color: #f5f5f5;
    display: grid;
    /* grid-template-columns: repeat(4, 1fr) 3%; */
    grid-template-columns: repeat(4, 1fr);
}

.home__grid{
    margin-top: 10%;
    grid-column: span 1;
    grid-row: span 4;
}

.home__grid2{
    margin-top: 10%;
    grid-column: span 1;
    grid-row: span 4;
    border-left: solid 1px   #f5f5f5;
}

.home__grid3{
    margin-top: 10%;
    grid-column: span 1;
    grid-row: span 4;
    border-left: solid 1px  #f5f5f5;
}

.home_loading{
    display: flex;
    align-items: center;
}

.home_loading_in{
    margin: 50px auto;
}

@media screen and (max-width: 768px) {
    .home__grid{
        grid-column: span 2;
    } 

    .home__grid2{
        grid-column: span 2;
    } 

    .home__grid3{
        grid-column: span 2;
        border-left: transparent;
    } 

    .home_conteiner_grid{
        grid-template-columns: repeat(2, 1fr);
    }

    .prueba_cad2_1{
        border-right: solid 1px #f5f5f5;
    }

}

.container1{
    z-index: 1;
    min-width: 100%;
    display: grid;
    /* grid-template-columns: repeat(4, 1fr) 3%; */
    grid-template-columns: repeat(4, 1fr);
    border: solid 1px #d9d9d9;
    -webkit-margin-after: 50px;
            margin-block-end: 50px;
    border-top: transparent;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: #ffffff;
}

.modalpading{
    padding: 0 !important;
    margin: 0 !important;
}

.pub__card{
    grid-column: span 1;
    margin-left: 0px;
    margin-right: 0px;
    grid-column: span 1;
    height: 75px;
    align-items: center;
    text-decoration: none !important;
}

.flex{
    display: flex;
    text-decoration: none !important;
}

.pub__cent{
    margin-left: 15px;
    margin-top: 16px;
    display: flex;
}

.text{
    margin-left: 10px;
    margin-top: 7px;
    color: #808080 !important;
    text-decoration: none !important;
}


.p1{
    border-top: solid #00a1f1 4px;
    border-right: solid 1px #d9d9d9;
}

.p2{
    border-top: solid #ea4335 4px;
    border-right: solid 1px #d9d9d9;
}

.p3{
    border-top: solid #ffbb00 4px;
    border-right: solid 1px #d9d9d9;
}

.p4{
    border-top: solid #7bbb00 4px;
}

.publicidad_cursor{
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .pub__card{
        grid-column: span 4;
    }

    .container1{
        margin-top: 50px;
    }
}
.pagos1, .pagos2{
    margin-top: 50px;
    -webkit-border-after: solid 1px rgb(227, 228, 233);
            border-block-end: solid 1px rgb(227, 228, 233);
    display: solid;
    margin:auto;
}

.pagos_grid{
    grid-column: span 1;
    grid-row: span 2;
    margin: auto;
}

.pagos_header{
    z-index: 1;
    display: grid;
    margin: 0;
}

@media screen and (max-width: 768px) {


    .pagos1{
        -webkit-border-after: none;
                border-block-end: none;
        max-width: 768px;
        display: none;
    }

    .pagos2{
        position: relative;
        bottom: 53px;
        display: none;
    }



}
.card-body{
    background: white;
    border: white solid 3px;
    font-family: "Verdana", "Lucida Grande", "Helvetica", Arial, sans-serif;
    font-size: 100%;
}

.cards__cont{
    border: transparent !important;
}

.card-body:hover{
    border: #ffbb00 solid 3px;
}

.card__container{
    width: 100%;
    height: 400px;
    border: transparent;
    border-radius: 0%;
    align-items: center;
    text-align: center;
}

.card_old_price{
    text-decoration: line-through;
    margin-bottom: 0;
    font-size: 80%;
    color: #ea4335;
    margin-top: -5px;
}

.cards_promotion{
    position: absolute;
    z-index: 100;
    background-color: red;
    color: white;
    top: 25px;
    left: 0.5px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    font-family: "Montserrat";
    font-size: 20px;
}

.card_now_price{
    margin-bottom: 0;
    font-size: 80%;
    color: #a5a6a6;
}

.card-body .visible2{
    visibility: hidden;
    opacity: 0;
    height: 0;
    padding: 0;
}

.card-body:hover .visible2 {
    height: auto;
    visibility: visible;
    opacity: 10;
    transition: opacity 0s ease;
}

.card-body:hover .visible{
    visibility: hidden;
    opacity: 0;
    height: 0;
    padding: 0;
}

.card__icons{
    align-items: center;
    text-align: center;
    transition: 1s ease;
    display: flex;
    margin: 0 auto;
}

.icon1{

    margin-right: 7px;
    padding: 10px;
}


.icon2{
    margin-left: 7px;
    padding: 10px;
    margin-right: -2px;
}

.icon3{
    width: 90px;
    padding: 10px;
}

.cards__img{
    max-height: 300px;
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    border: transparent !important;
    
}


.cards__img_cont{
    max-height: 300px;
    min-height: 300px;
    max-width: 300px;
    display: flex;
    align-items: center;
}

.cards__body{
    align-items: center;
    text-align: center;
}

.cards__price{
    color: #264796 !important;
    font-weight: bold !important;
}

.card_mob_max{
    max-width: 100%;
    background-color: wheat;
}

.prueba_mob_container{
    margin: 0 auto;
    z-index: 1;
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.card_mob_body{
    max-width: 100%;
    align-items: center;
    margin-top: 5px;
    font-family: "Verdana", "Lucida Grande", "Helvetica", Arial, sans-serif;
}

.card_mob_container{
    max-width: 100%;
    height: 300px;
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    
}

.card_mob_img{
    max-width: 100%;
    grid-row: span 3;
    background-color: white;
    align-items: center;
    display: flex;
}

.card_mob_img > img{
    margin: 0 auto;
    max-height: 97%;
    max-width: 97%;
    min-height: 97%;
    
}

.card_mob_footer{
    grid-row: span 2;
    background-color: white;
    text-align: center;
}

.card_mob_footer_title{
    margin-top: 5px;
    margin-bottom: 5px;
    color: black !important;
    font-size: 90%;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    line-height: 1.1em;

}

.card_mob_oldprice{
    color: #ea4335;
    font-size: small;
    text-decoration: line-through;
    margin-bottom: -5px;
}

.card_mob_oldprice2{
    color: transparent;
    font-size: small;
    text-decoration: line-through;
    margin-bottom: -5px;
}

.card_mob_price{
    font-size: 1.3em;
    color: #264796;
    font-weight: bold;
    margin-bottom: 5px;
}






@media screen and (max-width: 768px) {
    .cards__img{
        max-width: 150px !important;
        min-width: 0;
        height: 150px !important;
        min-height: 0;
        margin-left: -10px;
    }

    .card-body:hover .cards__img{
        display: flex;
        margin-left: 30px;

    }

    .card-body:hover .cards__body{
        margin-left: -5px;
    }

    .cards__body{
        align-items: center;
        max-width: 140px;
        min-width: 140px;
        margin-left: 27px;
    }

    .cards__title{
        font-size: small;
    }

    .card__container{
        width: 100px;
        height: 300px;
        border-left:transparent;
        align-items: center;
    }

    .cursor :hover{
        border: transparent
    }

    .cursor :hover > .cards__img{
        cursor: none;
    }

    .card-body:hover .icon3{
        position: relative;
        top: 50px;
        right: 75px;
        border: solid 1px black;
        min-width: 80px ;
    }

    .icon1{
        font-size: x-small;
        padding: 5px;
        font-family: "Verdana", "Lucida Grande", "Helvetica", Arial, sans-serif;
        font-weight: bold;
        color: white;
        border-radius: 20px;
    }


}

@media screen and (max-width: 368px) {
    .cards__img{
        height: 140px;
    }
    .card__container{
        width: 150px;
        height: 408px;
    }

    .cards__title{
        min-height: 100px;
    }

}


.modal_header{
    background: rgb(60, 59, 137) !important;
    border-radius: 0 !important;
    outline: none;
}

.modal_title{
    color: white !important;
}

.modal_footer{
    border-radius: 0 !important;
    background: white;
}

.modal_max{
    margin-top: 110px;
}

.modal_envios{
    z-index: 1000000000;
}
.carousel_cards{
    width: 100%;
    margin: 0 auto;
}

.carouselc_container{
    margin: 0 auto;
    border-top: solid 1px rgb(227, 228, 233);
    border-bottom: solid 1px rgb(227, 228, 233);
    border-right: solid 1px rgb(227, 228, 233);
}

.carousel-button-group{
    position: absolute;
}

.cards_caro{
    border-left: solid 1px rgb(227, 228, 233);
}

.cards_caro_ultimo{
    border-left: solid 1px rgb(227, 228, 233);
    border-right: solid 1px rgb(227, 228, 233);
}

.titulo_body{
    text-align: center;
    max-width: 1500px;
    margin-bottom: 20px;
    margin-top: 50px;
    font-size: 100%;
    
}
.lineas-effect{
    max-width: 1500px;
}

h1{
    font-family: "Verdana", "Lucida Grande", "Helvetica", Arial, sans-serif;
    font-weight: bold;
    display: inline-block;
    position: relative;
    font-size: 1.8em;
    color: rgb(36, 36, 87);;
}

h1::after,h1::before{
    content: '';
    position: absolute;
    width: calc(700px - 50%);
    height: 3px;
    background-color: currentColor;
    top: 0.6em;
}

h1::before{
    left: calc(-720px + 50%);
}

h1::after{
    right: calc(-720px + 50%);
}

@media screen and (max-width: 1500px) {
    h1::after,h1::before{
        width: calc(600px - 50%);
    } 
    h1::before{
        left: calc(-620px + 50%);
    }
    
    h1::after{
        right: calc(-620px + 50%);
    }
}

@media screen and (max-width: 1300px) {
    h1::after,h1::before{
        width: calc(500px - 50%);
    } 
    h1::before{
        left: calc(-520px + 50%);
    }
    
    h1::after{
        right: calc(-520px + 50%);
    }
}

@media screen and (max-width: 1064px) {
    h1::after,h1::before{
        width: calc(400px - 50%);
    } 
    h1::before{
        left: calc(-420px + 50%);
    }
    
    h1::after{
        right: calc(-420px + 50%);
    }
}

@media screen and (max-width: 860px) {
    h1::after,h1::before{
        width: calc(150px - 50%);
    } 
    h1::before{
        left: calc(-170px + 50%);
    }
    
    h1::after{
        right: calc(-170px + 50%);
    }
}
.bennerpub_header{
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.bennerpub_img{
    max-width: 100%;
}
.cards2_img{
	max-width: 100%;
	max-height: 22vw;
	min-width: 100%;
}

.cards2_img_cont{
	width: 22vw;
	height: 22vw;
	border-bottom: solid 1px rgb(227, 228, 233);
	display: flex;
	align-items: center;
	margin: 0 auto;
	max-width: 100%;
}

.cards2_img_max{
	margin-bottom: 20px;
	border: solid 1px rgb(227, 228, 233);
	height: calc(25vw + 100px);
	margin: 10px;
	background-color: white;
	max-width: 350px;
}

.cards2_img_oferta{
	position: relative;
	background-color: red;
	color: white;
	padding: 5px 10px;
	border-radius: 0 5px 5px 0;
	top: 10px;
	width: 80px;
	font-family: "Montserrat";
    font-size: 20px;
}

.cards2_border{
	border: solid 3px white;
	height: 100%;
}

.cards2_border:hover{
	border: solid 3px #ffbb00;
	height: 100%;
}

.cards2_oferta_off{
	visibility: hidden;
    opacity: 0;
    width: 0;
    padding: 0;
	margin-top: 15px;
}

.cards2_body_cont{
	margin-top: 10px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: "Verdana", "Lucida Grande", "Helvetica", Arial, sans-serif;
    font-size: 100%;
	text-align: center;
	max-width: 100%;
}

.cards2_body_cont_butt{
	margin: 0 auto;
}

.cards2_body_cont2{
	margin-top: 10px;
	width: 100%;
	display: flex;
	align-items: center;
	font-family: "Verdana", "Lucida Grande", "Helvetica", Arial, sans-serif;
    font-size: 100%;
}
.footer{
    margin-top: 0 !important;

}

.footer_cont{
    max-width: 1500px;
    margin: 0 auto;
}

.footer__back{
    margin-top: 0;
    border-top: solid 3px rgb(33, 33, 61);
    background-color: #1b1464;
}

.footer__inf{
    background-color:  rgb(33, 33, 61);
}

.footer__letra{
    color: snow;
}

.footer__margin{
    margin-top: 5px;
    margin-left: 10px;
}

.footer__orden{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.footer_lineado{
    border-right:dashed 1px white;
}


.footer__icon{
    size: 1.5em;
    grid-gap: 1.5em;
    gap: 1.5em;
    margin-left: -10%;
}

.footer__icon2{
    size: 1.5em;
    grid-gap: 1.5em;
    gap: 1.5em;
    margin-top: 2px;
    margin-left: 10%;
}

.imagenfb{
    max-width: 37px;
}

.imagenig{
    max-width: 30px;
    
}

.footer_map{
    position: absolute;
    max-width: 100px;
    max-height: 100px;
    z-index: 10;
}

.footer_envios{
    margin-left: 5px;
}

.footer_title{
    text-align: center;
    align-items: center;
    margin-top: -20px;
    margin-bottom: 10px;
}

.footer_title2{
    text-align: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.footer_dirname{
    text-align: center;
}


.footer_direccion{
    max-width: 300px;
    margin-bottom: 10px;

}

.footer_truck{
    display: flex;
    align-items: center;
    margin-left: -15%;
}

.footer_logo_BPA{
    max-height: 50px;
    opacity: 0.7;
}



@media screen and (max-width: 768px) {

.footer__icon{
    size: 1.5em;
    grid-gap: 1.5em;
    gap: 1.5em;
    margin-left: 25%;
    margin-right: -25%;
    font-size: 1.5em;
}

.footer__icon2{
    size: 1.5em;
    grid-gap: 1.5em;
    gap: 1.5em;
    margin: 0 auto;
    font-size: 1.5em;
}

.footer_title{
    margin-top: 10px;
}

.footer_lineado{
    border-right: none;
}

.footer__margin{
    margin-left: -3%;
}

.footer_dirname{
    margin-left: -2%;
}
.footer_direccion{
    margin-left: -15%;
}

}
.wpp__icon{
    max-width: 65px;
    position: fixed;
    right: 10%;
    top: 85%;
    z-index: 10;
}

.wpp__fixed{
    position: absolute;
}
.checkout{
    max-width: 1500px;
    width: 90%;
    margin: 30px auto;
    display: grid;
    grid-gap: 1.0em;
    gap: 1.0em;
    /* grid-template-columns: repeat(4, 1fr) 3%; */
    grid-template-columns: repeat(3, 1fr);
    font-family: source-sans-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
    
}

.checkout_title_max{
    grid-column: 1 / -1;
    text-align: center;
    font-size: 300%;
    margin-bottom: 10px;
    background: #7f8b94;
    color: white;
    border-bottom: solid 1px #6b747b;
}

.checkout_cont_vol{
    border: solid 1px rgb(227, 228, 233);
    background-color: rgb(227, 228, 233);
    border-radius: 10px;
}

.checkout_product{
    grid-column:  span 2;
}

.checkout_title{
    margin-right: 10px;
    padding: 10px;
    border-bottom: 1px solid lightgray;
}

.check_ad{
    max-width: 80%;
    margin: 25px auto;
}

.checkout_id_inv{
        visibility: hidden;
        opacity: 0;
        height: 0;
        padding: 0;
}

.checkout_voleta{
    grid-column:  span 1;
    max-height: 100%;
}

.chekout_subtotal{
    max-width: 85%;
    margin: 0 auto;
    grid-template-columns: repeat(3, 1fr);
    display: grid;
}

.checkout_voleta_text{
    margin-bottom: 15px;
    grid-column:  span 2;
}

.checkout_voleta_price{
    margin-bottom: 15px;
    margin-left: auto;
}

.checkout_voleta_price2{
    margin-left: auto;
}

.checkout_voleta_text2{
    grid-column:  span 2;
}



.checkout_border_bot{
    border-bottom: black solid 1px;
    max-width: 80%;
    margin: 25px auto;
}

.checkout_presu_title{
    margin-top: 30px;
    text-align: center;
    font-size: x-large;
}

.checkout_buttons{
    margin: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    align-content: center;
}

.checkout_buttons2{
    display: flex;
    margin: 0 auto;
    text-decoration: none !important;
}

.checkout_button_separated1{
    margin-left: 7px;
}

.checkout_button_separated2{
    margin-right: 7px;
}

@media screen and (max-width: 768px) {
    .checkout_product{
        grid-column: 1 / -1;
    }

    .checkout_voleta{
        grid-column: 1 / -1;
    }
}
.cardCheck_container{
    max-height: 200px;
    width: 90%;
    margin: 10px auto;
    display: grid;
    grid-gap: 1.0em;
    gap: 1.0em;
    /* grid-template-columns: repeat(4, 1fr) 3%; */
    grid-template-columns: repeat(3, 1fr);
    font-family: source-sans-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
    
}

.cardCheck_container_abs{
    border-bottom: solid 1px rgb(227, 228, 233);
}

.cardCheck_imgcont{
    grid-column:  span 1;
    display: grid;
}

.cardCheck_img{
    max-height: 200px;
    max-width: 100%;
    border-radius: 10px;
}

.cardCheck_info{
    margin-top: 5px;
    grid-column:  span 2;
}


.cardCheck_button{
    display: flex;
    margin-top: 3%;
}

.cardCheck_cantidad{
    display: flex;
    font-weight: 400 !important;
    color: #494d59;
}

.cardCheck_espaciado{
    margin-top: 2%;
}

.cardCheck_cantidad2{
    margin-left: 20px;
}

.cardCheck_button_espaciado{
    margin-right: 5px;
}

@media screen and (max-width: 768px) {
    .cardCheck_imgcont{
        grid-column: 1 / -1;
        align-items: center;
        text-align: center;
        margin: 0 auto;
    }



    .cardCheck_info{
        grid-column: 1 / -1;
    }

    .cardCheck_container{
        max-height: none;
        align-items: center;
        text-align: center;
        border-bottom: none;
    }

    .cardCheck_cantidad{
        display: flex;
        text-align: center;
        align-items: center;
        margin-left: 25%;
    }

    .cardCheck_button{
        display: flex;
        align-items: center;
        text-align: center;
        margin-left: 25%;
    }

    .cardCheck_cel{
        margin-left: 37% !important;
    }

    .cardCheck_font_weight{
        font-weight: 400 !important;
    }

}
/* MOBILE */

.products_mob_container{
    max-width: 90%;
    margin: 20px auto;
    font-family: "Verdana", "Lucida Grande", "Helvetica", Arial, sans-serif;
}

.products_mob_galery{
    height: 200px;
}

.products_mob_sidebar{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.products_mob_title{
    font-size: 1.5em;
}

.products_mob_price_cont{
    margin-top: 15px;
    display: flex;
}

.products_mob_price{
    color: #264796 !important;
    font-weight: bold !important;
}

.products_mob_price_unit{
    margin-top: 11%;
    margin-left: 3px;
    color: gray;
    font-size: 0.5em;
}

.products_mob_pay{
    margin-top: 15px;
}

.products_mob_center{
    margin-top: 15px;
    margin-left: 25%;
}

.products_mob_center_botton{
    padding: 10px;
    font-size: 80%;
    margin-left: 10px;
}

.products_mob_title_rel{
    margin-top: 50px;
    font-family: "Verdana", "Lucida Grande", "Helvetica", Arial, sans-serif;
    font-weight: bold;
    font-size: 1.3em;
    margin-bottom: 15px;
    color: rgb(36, 36, 87);;
}
   

/* DESKTOP*/

.title h1 {
    padding: 20px 0;
    text-align: center;
    font-size: 2.9em;
    text-transform: uppercase;
}

.flex{
    display: flex;
}

.pub_back{
    font-family: "Verdana", "Lucida Grande", "Helvetica", Arial, sans-serif;
    background: #ffffff;
    max-width: 1500px;
    margin: 0 auto;
    margin-top: 30px;
}

.precio_mts{
    margin-top: 40px;
    margin-left: 3px;
    color: gray;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.8em;
}

/* CONTAINER */

.container {
    width: 90%;
    max-width: 1500px;
    margin: 0 auto;
    display: grid;
    grid-gap: 1.0em;
    gap: 1.0em;
    /* grid-template-columns: repeat(4, 1fr) 3%; */
    grid-template-columns: repeat(6, 1fr);
}

.container>.header, .container>.content, .container>.sidebar, .container>.footer, .container>div {
    margin-top: 20px;
    background: #fff;
    padding: 20px;
}


.container>.public_info{
    grid-column: span 4;
    grid-row: span 6;
    display: grid;
    grid-area: 1.6em;
    grid-template-columns: repeat(3, 1fr);
    border: transparent;
    margin-top: 0;
    max-height: 150px;
}

.products_description{
    grid-column: 1/-1;
}

.products_description_title{
    font-family: Proxima Nova,-apple-system,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
    font-size: 24px;
    font-weight: 400;
    padding-bottom: 24px;
    color: rgba(0,0,0,.8);
}

.products_description_text{
    color: #666;
    font-size: 20px;
    font-family: Proxima Nova,-apple-system,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
}

.products_button_add{
    margin-bottom: 20px;
}

.info_carac{
    display: grid;
    grid-area: 1.6em;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    align-items: center;
    border: transparent !important;
}

.carecteristicas1{
    grid-column: span 1;
    display: grid;
    grid-area: 1.6em;
    grid-template-rows: repeat(3, 1fr);

}

.carecteristicas2{
    grid-column: span 2;
    border-left: transparent;
    display: grid;
    grid-area: 1.6em;
    grid-template-rows: repeat(3, 1fr); 
}

.carac_color1{
    background: #ebebeb;
    border-top-left-radius: 10px;
}

.carac_color4{
    background: #ebebeb;
    border-bottom-left-radius: 10px;
    display: inline-table;

}

.carac_color4>p{
    margin-left: 10px;
    vertical-align: middle;
    position: relative;
    top: 6px;
}


.carac_color1>p{
    margin-left: 10px;
    position: relative;
    top: 6px;
}

.carac_color2{
    background: #f5f5f5;
    border-top-right-radius: 10px;
}

.carac_color2>p{
    margin-left: 10px;
    position: relative;
    top: 6px;
}

.carac_color5{
    background: #f5f5f5;
    border-bottom-right-radius: 10px;
}

.carac_color6>p{
    margin-left: 10px;
    position: relative;
    top: 6px;
}

.carac_color6{
    background: #f5f5f5;
}

.carac_color5>p{
    margin-left: 10px;
    position: relative;
    top: 6px;
}


.carac_color3>p{
    margin-left: 10px;
    position: relative;
    top: 6px;
}


.container>.header, .container>.footer {
    grid-column: 1/-1;
    background-color: #1e272e;
    color: #ffffff;
}

.container>.content {
    grid-column: span 4;
    grid-row: span 3;
    border: transparent;
    background: #ffffff;
    min-height: 50vw;

}

.content_border{
    background: #ffffff;
    padding-bottom: 50px;
    border-bottom: solid 1px rgb(227, 228, 233);
}

.imagen{
    max-width: 100%;
}

.container>.pub_rel{
    grid-column: 1/-1;
    text-align: center;
    border: transparent;
    background: transparent;
    border-top: solid 1px rgb(227, 228, 233);
}

.content>.article {
    background: #808e9b;
    padding: 20px;
    margin-top: 5px;
    color: #dfe6e9
}

.content .article h1,
.content .article p {
    padding: 10px;
}

.container>.sidebar {
    grid-column: span 2;
    grid-row: span 4;
    background: #ffffff;
    color: black;
    display: flex;
    border: transparent;
    flex-direction: column;
    border: solid rgb(227, 228, 233) 1px;
    border-radius: 10px;
}

.container .sidebar .sidebar_title {
    padding: 20px 0;
    font-size: 1.3em;
    text-transform: uppercase;
}

.container .sidebar .sidebar_price {
    padding: 10px 0;
    font-size: 2.0em;
    font-family:Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    color: rgb(18, 18, 238);
}

.container .sidebar .metodos_pago {
    padding: 10px 0;
}

.imagen_tarjeta{
    margin-top: 5px;
    max-height: 30px;
}

.container>.sidebar>.text_row{
    flex-direction: row;
}

.form_cantidad{
    margin-top: 20px;
}

.container>.related-post .text__center {
    visibility: hidden;
    opacity: 0;
    height: 0;
    padding: 0;
}

.container>.related-post:hover .text__center{
    height: auto;
    visibility: visible;
    opacity: 10;
    transition: opacity 0.5s ease;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
}

.container>.related-post img{
    height: 100%;
    width: 100%;
    padding: 0;

}

.container>.related-post:hover img{
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
}


.container>.related-post {
    color: #ffbb00;
    height: 180px;
    width: 180px;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
    position: relative;
    display: inline-block;
    text-align: center;
    padding: 0;
    border: transparent;
    background: transparent;
    margin-bottom: 20px;
    margin-top: -20px;
}

.container>.related-post:hover {
    cursor: pointer;
}

/* MEDIA QUERIES */

@media screen and (max-width: 768px) {
    .header {
        text-align: center;
    }
    .container .content {
        grid-column: 1 / -1;
    }
    .container .sidebar {
        grid-column: 1 / 6;
    }

    .container .related-post {
        grid-column: 1/-1;
    }

    .imagen_tarjeta{
        margin-left: 25%;
    }


}

@media screen and (min-width: 1500px) {
    .sidebar{
        height: 650px;
    }
    .container>.public_info{
        margin-top: -9vw;
    }
}
.galery_container{
    min-height: 450px;
}
.Gal_container{
    display: flex;
    width: 100%;
    height: calc(50vw - 100px);
    background-color: white;
}

.Gal_lista{
    width: 100px;
}

.Gal_list_img >img{
    max-width: 100%;
}

.Gal_list_img{
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    border: rgb(227, 228, 233) solid 1px;
    border-radius: 5px;
    padding: 3px;
    margin: 10px auto;
    background-color: white;
}

.Gal_img_img > img{
    max-width: 100%;
    max-height: 100%;
}

.Gal_img{
    height: 100%;
    width: 100%;
    display: flex;
    background-color: white;
    padding: 5%;
}

@media screen and (min-width: 1500px) {
    .Gal_container{
        height: calc(750px - 100px);
    }
}
.category_container{
    max-width: 1500px;
    z-index: 1;
    max-width: 1500px;
    background-color: white;
    display: grid;
    /* grid-template-columns: repeat(4, 1fr) 3%; */
    grid-template-columns: repeat(4, 1fr);
}

.category_title{
    margin: 20px auto;
}

.category_max{
    max-width: 1500px;
    margin: 0 auto;
}

.category_bottom{
    margin-top: 15px;
    border-bottom: solid 1px rgb(227, 228, 233);
    border-top: solid 1px rgb(227, 228, 233);
}


@media screen and (max-width: 768px) {
    .category_container{
        grid-template-columns: repeat(2, 1fr);
    }
}
.adminHome_container{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    border-radius: 4px;
    margin: 0 auto;
}

.adminHome_header{
    background-color: rgb(60, 59, 137);
    min-width: 100%;
    padding: 5px;
}

.adminHome_header_button{
    margin-left: 90vw;
}

.adminHome_bar{
    background-color: rgb(60, 59, 137);
    display: flex;
    flex-direction: column;
}

.adminHome_img{
    margin: 25px auto;
    max-width: 90%;
    margin-left: 5px;
}

.adminHome_bar_container{
    display: flex;
    flex-direction: column;
}

.clas_border{
    border-top: solid 2px white;
    padding: 10px;
    color: white;
    text-align: center;
}

.clas_border:hover{
    color: white !important;
    text-decoration: none !important;
}

.clas_border_botton{
    border-top: solid 2px white;
    min-height: 650px;
    color: rgb(60, 59, 137);
}

.adminHome_component{
    grid-column: span 6;
}

.adminHome_route_in{
    background-color: rgb(26, 26, 59);
}
.Ceramicas_container{
    width: 100%;
}

.Ceramicas_nabbar{
    display: flex;
    background-color: rgb(60, 59, 137);
    min-width: 100%;
    color: white;
    align-items: center;
    text-align: center;
}

.Ceramicas_nabbar_buttons{
    padding: 10px;
    background-color: rgb(26, 26, 59);
    margin-left: 50px;
    margin-top: 15px;
    margin-bottom: 15px;
    border: solid 1px black;
    border-radius: 10px;
}

.Ceramicas_margin{
    margin-left: 50px;
    margin-top: 10px;
}

.ceramicas_filtro{
    margin-left: 50px;
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.ceramicas_filtro_div{
    margin: 0 auto;
}

.ceramicas_filtro_div2{
    margin: 0 auto;
    display: flex;
}

.ceramicas_filtro_button{
    margin-left: 5px;
    margin-top: 30px;
}

.ceramicas_button{
    margin-top: 30px;
}

.ceramicas_check{
    height: 50px;
}

.CardAdmin_container{
    border: solid 1px;
    display: flex;
    align-items: center;
    max-width: 90%;
    border-radius: 10px;
}

.CardAdmin_img{
    max-width: 50px;
    min-height: 50px;
    border-radius: 10px;
    margin: 5px;
}

.CardAdmin_img > img{
    max-width: 50px;
    min-height: 50px;
    border-radius: 10px;
}

.CardAdmin_margin{
    margin-left: 10px;
}

.CardAdmin_name{
    width: 23%;
}

.CardAdmin_price{
    width: 10%;
}

.CardAdmin_model{
    width: 23%;
}

.CardAdmin_dimension{
    width: 18%;
}

.CardAdmin_inactive{
    background-color: gray;
}
.NewProduct_container{
    margin: 0 auto;
    max-width: 40%;
    border: solid 1px black;
    border-radius: 10px;
    background-color: #f5f5f5;
    padding: 50px;
    text-align: center;
}

.NewProduct{
    background-color: white;
}

.NewProduct_modal_marg{
    margin-top: 30px;
}

.NewProduct_button{
    margin-left: 10px;
}

.NewProduct_modal{
    background-color: rgb(26, 26, 59);
    border: none;
    color: white;
}

.NewProduct_modal:hover{
    background-color: rgb(26, 26, 59);
}

.NewProduct_alert{
    position: fixed;
    top: 0;
    left: 5vw;
    width: 90vw;
    z-index: 2001;
    text-align: center;
}

.NewProduct_prev_img{
    max-width: 75px;
    margin-right: 5px;
    border-radius: 5px;
    
}

.NewProduct_prev_img_cont{
    display: flex;
    
}

.NewProduct_img_number{
    position: relative;
    bottom: 53%;
    left: 33%;
    color: white;
    font-size: 2.5em;
}

.NewProduct_description{
    display: flex;
    flex-direction: column;
}
.Edit_images_img{
    max-width: 100%;
}

.Edit_images_img_cont{
    width: 80px;
    height: 80px;
    border: solid 1px gray;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin: 5px;
}

.Edit_images_cont{
    display: flex;
}

.Edit_images_img_shadow{ 
    box-shadow: 0px 0px 19px 0px blue;
    border: solid 1px blue !important;
}

.Edit_images_delete{
    background-color: red;
    color: white;
    width: 22px;
    text-align: center !important;
    align-items: center !important;
    margin: 0 auto;
    border-radius: 5px;
    cursor: pointer;
}
.AdminInicio{
    max-width: 1000px;
    margin: 0 auto;
}

.AdminInicio_cards_contador{
    width: 30%;
    height: 100px;
    display: flex; 
    color: white;
    margin: 0 auto;
}

.AdminInicio_cards_text{
    margin-top: 10px;
    margin-left: 20px;
    margin-right: -20px;
    width: 70%;
}

.AdminInicio_cards_icon{
    background-color: white;
    width: 42px;
    height: 42px;
    align-items: center;
    padding: 6px;
    border-radius: 50%;
    margin-top: 29px;
}

.AdminInicio_cards_text_number{
    font-size: 1.7em;
    text-align: center;
}

.AdminInicio_cards_text_desc{
    text-align: center;
    font-size: 1.1em;
}

.AdminInicio_cards_contador_container{
    display: flex;
    margin: 45px auto;
}

.AdminInicio_cards_color1{
    border: solid 1px #375804;
    background-color: #68ae00;
}

.AdminInicio_cards_color2{
    border: solid 1px #a15107;
    background-color: #fc8213;
}

.AdminInicio_cards_color3{
    border: solid 1px #09355c;
    background-color: #347ab8;
}

.AdminInicio_grafic{
    display: flex;
}

.AdminInicio_grafic_cards{
    width: 45%;
    margin: 45px auto;
    background-color: white;
}
.AdminPresupuesto{
    align-items: center;
    display: flex;
    flex-direction: column;
}

.AdminPresupuesto_busqueda{
    display: flex;
}

.AdminPresupuesto_busqueda_title{
    margin: 20px;
}

.AdminPresupuesto_busqueda_button{
    margin-top: 52px;
}

.AdminPresupuesto_card{
    border: solid black 1px;
    margin: 10px;
    width: 50vw;
    background-color: white;
    border-radius: 10px;
}

.AdminPresupuesto_cont{
    border: solid black 1px;
    border-radius: 10px;
    margin: 10px auto;
    width: 90%;
}

.AdminPresupuesto_card_title{
    margin: 5px;
    text-align: center;
}

.AdminPresupuesto_prod{
    margin: 10px auto;
    width: 90%;
    text-align: center;
}

.AdminPresupuesto_card_buttons{
    align-items: center;
    display: flex;
}

.AdminPresupuesto_card_button{
    margin: 5px auto;
}

.AdminPresupuesto_buttonL{
    margin-left: 8px;
}

.AdminPresupuesto_buttonR{
    margin-right: 8px;
}
.CarouselAdmin_img{
    max-height: 100px;
    border-radius: 10px;
}

.CarouselAdmin_container{
    border: solid 1px black;
    border-radius: 10px;
    display: flex;
    max-height: 100px;
    margin-top: 20px;
}

.CarouselAdmin{
    margin: 50px;
}

.CarouselAdmin_timer{
    margin-top: 35px;
    margin-left: 25px;
}

.CarouselAdmin_timer_width{
    min-width: 20vw;
}

.CarouselAdmin_timer>input{
    max-width: 100px;
}
.BannerAdmin{
    margin: 50px;
}

.BannerAdmin_container{
    border: solid 1px black;
    border-radius: 10px;
    margin-top: 20px;
}

.BannerAdmin_img{
    max-width: 100%;
    padding: 10px;
}

.BannerAdmin_cont{
    display: flex;
}

.BannerAdmin_input{
    margin-top: 15px;
    margin-left: auto;
}

.BannerAdmin_save{
    margin-left: auto;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: 10px;
}

.BannerAdmin_margin{
    margin-left: 10px;
}

.BannerAdmin_title{
    text-align: center;
}
.AdminLogin{
    min-width: 100%;
    height: 100vh;
    padding-top: 20vh;
}

.AdminLogin_container{
    max-width: 300px;
    margin: 0 auto;
    border: solid 1px black;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
}
.buscar_title{
    margin:25px;
    
}
.prueba_cat_container{
    max-width: 1500px;
    z-index: 1;
    max-width: 1500px;
    background-color: white;
    display: grid;
    /* grid-template-columns: repeat(4, 1fr) 3%; */
    grid-template-columns: repeat(4, 1fr);
}

