.AdminLogin{
    min-width: 100%;
    height: 100vh;
    padding-top: 20vh;
}

.AdminLogin_container{
    max-width: 300px;
    margin: 0 auto;
    border: solid 1px black;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
}