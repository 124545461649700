.banner_width {
    width: 100%;

}

@media screen and (max-width: 768px) {
    
    .banner_width {
        margin-top: 2%;
    }


}