.wpp__icon{
    max-width: 65px;
    position: fixed;
    right: 10%;
    top: 85%;
    z-index: 10;
}

.wpp__fixed{
    position: absolute;
}