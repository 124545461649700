.home{
    margin-top: 5%;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    background-color: #f5f5f5;
}

.prueba_cad2_1{
    display: grid;
    max-width: 365px;
}

.home__row{
    z-index: 1;
    margin-right: 5px;
    margin-left: 5px;
    display: grid;
    /* grid-template-columns: repeat(4, 1fr) 3%; */
    grid-template-columns: repeat(4, 1fr);
}

.home_conteiner_grid{
    max-width: 1500px;
    z-index: 1;
    background-color: #f5f5f5;
    display: grid;
    /* grid-template-columns: repeat(4, 1fr) 3%; */
    grid-template-columns: repeat(4, 1fr);
}

.home__grid{
    margin-top: 10%;
    grid-column: span 1;
    grid-row: span 4;
}

.home__grid2{
    margin-top: 10%;
    grid-column: span 1;
    grid-row: span 4;
    border-left: solid 1px   #f5f5f5;
}

.home__grid3{
    margin-top: 10%;
    grid-column: span 1;
    grid-row: span 4;
    border-left: solid 1px  #f5f5f5;
}

.home_loading{
    display: flex;
    align-items: center;
}

.home_loading_in{
    margin: 50px auto;
}

@media screen and (max-width: 768px) {
    .home__grid{
        grid-column: span 2;
    } 

    .home__grid2{
        grid-column: span 2;
    } 

    .home__grid3{
        grid-column: span 2;
        border-left: transparent;
    } 

    .home_conteiner_grid{
        grid-template-columns: repeat(2, 1fr);
    }

    .prueba_cad2_1{
        border-right: solid 1px #f5f5f5;
    }

}
