.titulo_body{
    text-align: center;
    max-width: 1500px;
    margin-bottom: 20px;
    margin-top: 50px;
    font-size: 100%;
    
}
.lineas-effect{
    max-width: 1500px;
}

h1{
    font-family: "Verdana", "Lucida Grande", "Helvetica", Arial, sans-serif;
    font-weight: bold;
    display: inline-block;
    position: relative;
    font-size: 1.8em;
    color: rgb(36, 36, 87);;
}

h1::after,h1::before{
    content: '';
    position: absolute;
    width: calc(700px - 50%);
    height: 3px;
    background-color: currentColor;
    top: 0.6em;
}

h1::before{
    left: calc(-720px + 50%);
}

h1::after{
    right: calc(-720px + 50%);
}

@media screen and (max-width: 1500px) {
    h1::after,h1::before{
        width: calc(600px - 50%);
    } 
    h1::before{
        left: calc(-620px + 50%);
    }
    
    h1::after{
        right: calc(-620px + 50%);
    }
}

@media screen and (max-width: 1300px) {
    h1::after,h1::before{
        width: calc(500px - 50%);
    } 
    h1::before{
        left: calc(-520px + 50%);
    }
    
    h1::after{
        right: calc(-520px + 50%);
    }
}

@media screen and (max-width: 1064px) {
    h1::after,h1::before{
        width: calc(400px - 50%);
    } 
    h1::before{
        left: calc(-420px + 50%);
    }
    
    h1::after{
        right: calc(-420px + 50%);
    }
}

@media screen and (max-width: 860px) {
    h1::after,h1::before{
        width: calc(150px - 50%);
    } 
    h1::before{
        left: calc(-170px + 50%);
    }
    
    h1::after{
        right: calc(-170px + 50%);
    }
}